// src/components/Content/Team.js
import React from 'react';
import styles from './Team.module.css';
import { FaInstagram } from 'react-icons/fa';

const teamMembers = [
  { name: 'Kieran', image: '/images/kl.jpeg', instagram: 'https://instagram.com/kieranlusher' },
  { name: 'Lewis', image: '/images/lewis.jpeg', instagram: 'https://instagram.com/lewisjamesphelps' },
  { name: 'Luca', image: '/images/luca.jpeg', instagram: 'https://instagram.com/luca_.2' },
  { name: 'Sonny', image: '/images/sonny.jpeg', instagram: 'https://instagram.com/sonnyburge.1' },
  { name: 'Macca', image: '/images/macca.jpeg', instagram: 'https://instagram.com/macca2406' },
  { name: 'Ralph', image: '/images/ralph.jpeg', instagram: 'https://instagram.com/antoniousbarber_' },
];

const Team = () => {
    return (
      <section className={styles.teamSection}>
        <h2 className={styles.subheading}>THE TEAM</h2>
        <div className={styles.teamGrid}>
          {teamMembers.map((member, index) => (
            <a 
              key={index} 
              href={member.instagram} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={styles.teamCard}
            >
              <img src={member.image} alt={member.name} className={styles.teamImage} />
              <h3 className={styles.teamName}>{member.name}</h3>
              <div className={styles.teamInstagram}>
                <FaInstagram className={styles.instagramIcon} />
                @{member.instagram.split('/')[3].toLowerCase()}
              </div>
            </a>
          ))}
        </div>
      </section>
    );
};

export default Team;
