// src/components/Content/Pricing.js
import React from 'react';
import styles from './Pricing.module.css';

// Path to the faded logo stored in the public folder
const fadedLogo = '/images/logo.png'; // Path from public folder



const services = [
  { name: 'Haircut', price: '£19' },
  { name: 'Skinfade/Taperfade', price: '£23' },
  { name: 'Scissor Cut (Long Hair)', price: '£21' },
  { name: 'Skinfade and Beard', price: '£28' },
  { name: 'Beard Trim', price: '£10' },
];

const Pricing = () => {
  return (
    <section className={styles.pricingSection}>
      <h2 className={styles.subheading}>PRICING</h2>
      <div className={styles.pricingGrid}>
        {services.map((service, index) => (
          <div key={index} className={styles.pricingCard}>
            <div
              className={styles.backgroundLayer}
              style={{
                backgroundImage: `url(${fadedLogo})`, // Set the background image here
              }}
            ></div>
            <h3 className={styles.serviceName}>{service.name}</h3>
            <p className={styles.servicePrice}>{service.price}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;
