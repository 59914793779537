// src/App.js
import React from 'react';
import Content from './components/content/Content';
import ImageSlider from './components/imageSlider/ImageSlider';
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
      <div className={styles.main}>
        <ImageSlider /> 
        <Content />
      </div>
    </div>
  );
}

export default App;
