// src/components/ImageSlider/ImageSlider.js
import React from 'react';
import Slider from 'react-slick';
import styles from './ImageSlider.module.css';

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        <div className={styles.slide}>
          <img src="/images/shop1.jpeg" alt="Slide 1" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop2.jpeg" alt="Slide 2" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop3.jpeg" alt="Slide 3" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop4.jpeg" alt="Slide 4" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop5.jpeg" alt="Slide 5" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop6.jpeg" alt="Slide 6" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop7.jpeg" alt="Slide 7" className={styles.image} />
        </div>
        <div className={styles.slide}>
          <img src="/images/shop8.jpeg" alt="Slide 8" className={styles.image} />
        </div>
        {/* <div className={styles.slide}>
          <img src="/images/shop9.jpeg" alt="Slide 9" className={styles.image} />
        </div> */}
      </Slider>
      {/* Overlay Container */}
      <div className={styles.overlay}>
      <h1 className={styles.overlayText}>
            LUSH<br />
            BARBERSHOP
          </h1>
      </div>
    </div>
  );
};

export default ImageSlider;
