// src/components/Content/Contact.js
import React from 'react';
import { FaInstagram, FaGoogle, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import styles from './Contact.module.css';

const instagramUrl = 'https://www.instagram.com/lushbarbershop';
const googleBusinessUrl = 'https://g.co/kgs/wi9YLgd';
const businessEmail = 'Lushbarbershop@gmail.com';

const Contact = () => {
  return (
    <section className={styles.contactSection}>
      <h2 className={styles.subheading}>GET IN TOUCH</h2>
      <ul className={styles.contactList}>
      <a 
        href={instagramUrl}
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.contactLink}>
        <li className={styles.contactItem}>
          <FaInstagram className={styles.icon} />

          <span>Instagram</span>
        </li>
        </a>
        <a 
        href={googleBusinessUrl}
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.contactLink}>
        <li className={styles.contactItem}>
          <FaGoogle className={styles.icon} />
          <span>Google Business</span>
        </li>
        </a>
        <a 
        href={`mailto:${businessEmail}`}
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.contactLink}>
        <li className={styles.contactItem}>
          <FaEnvelope className={styles.icon} />
          <span>Lushbarbershop@gmail.com</span>
        </li>
        </a>
        <li className={styles.contactItem}>
          <FaMapMarkerAlt className={styles.icon} />
          <span>72 Bath Rd, Cheltenham GL53 7JT</span>
        </li>
      </ul>
    </section>
  );
};

export default Contact;
