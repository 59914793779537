// src/components/Content/Content.js
import React from 'react';
import styles from './Content.module.css';
import Map from "../map/OpenLayersMap";
import Team from "../team/Team";
import Pricing from "../pricing/Pricing";
import Contact from "../contact/Contact";

  const handleButtonClick = () => {
    window.location.href = 'https://lushbarbershop.booksy.com';
  };

const Content = () => {
  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <img src="/images/logo.png" alt="Logo" className={styles.logo} />
        <button className={styles.bookNowButton} onClick={handleButtonClick}>Book Now</button>
      </header>
      <hr className={styles.separator} />
      <Map />
      <Team />
      <Pricing />
      <Contact />
      <footer className={styles.footerContainer}>
        2024 Lush Barbershop.
      </footer>
    </div>
  );
};

export default Content;
